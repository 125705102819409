import { onBeforeMount, computed } from '@vue/composition-api';
import store from '@/store';
import { useStoreModule } from '@/composition/useStoreModule';
import { extraStoreModule } from '@/store/extra/extraStore.module';

const STORE_MODULE_NAME = 'extraFuels';

const translationShort = {
  'lpg': 'Газ',
  'dp': 'Дизель',
  'dpplus': 'Дизель+',
  '80': 'А-80',
  '92': 'А-92',
  '95': 'А-95',
  '95plus': 'А-95+',
  '98': 'А-98',
  '100': 'А-100',
  'AD_BLUE': 'Adblue',
};

const translationLong = {
  'lpg': 'Газ',
  'dp': 'Дизельне пальне',
  'dpplus': 'Дизельне пальне Плюс',
  '80': 'Бензин А-80',
  '92': 'Бензин А-92',
  '95': 'Бензин А-95',
  '95plus': 'Бензин А-95+',
  '98': 'Бензин А-98',
  '100': 'Бензин А-100',
  'AD_BLUE': 'Adblue',
};

export function useFuels() {

  useStoreModule(STORE_MODULE_NAME, extraStoreModule);

  const fetchFuels =  async () => {
    await store.dispatch(`${STORE_MODULE_NAME}/fetchFuels`);
  };

  const fuels = computed(() => {
    return store.getters[`${STORE_MODULE_NAME}/GET_FUELS`];
  });

  function getFuelTitleShort(key) {
    return translationShort[key];
  }

  function getFuelTitle(key) {
    return translationLong[key];
  }

  onBeforeMount(async() => {
    await fetchFuels();
  });

  return { fuels, getFuelTitleShort, getFuelTitle };

}
