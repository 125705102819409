import {ref, watch, computed, reactive, toRefs} from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useInvoicesList() {
  // Use toast
  const toast = useToast();

  const calculation = ref({total: 0, amount: 0, discount: 0});

  const refInvoiceListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'actions1', label: 'Дії' },
    { key: 'executedAt', label: 'Виконаний', sortable: false, mobile: true },
    { key: 'id', label: '#', sortable: false, mobile: true, tdClass: 'custom-media-cell' },
    { key: 'driver', label: 'Водій', sortable: false, mobile: true, tdClass: 'custom-media-cell' },
    { key: 'serialVisible', label: 'Номер карти' },
    { key: 'typeAccount', label: 'Тип рахунку', sortable: false },
    { key: 'driverGroup', label: 'Група', sortable: false },
    { key: 'issuerName', label: 'АЗС', sortable: false },
    { key: 'fuelType', label: 'Тип пального', sortable: false },
    { key: 'amount', label: 'Літраж', sortable: false },
    { key: 'sum', label: 'Сума', sortable: false, formatter: val => `${(val / 100).toFixed(2)} грн` },
    { key: 'discount', label: 'Сума знижки', sortable: false },
    { key: 'isExecuted', label: 'Статус', sortable: false },
    { key: 'actions2', label: 'Дії', mobile: true, tdClass: 'custom-media-cell' },
  ];

  const filterData = {
    perPage: 10,
    totalInvoices: 0,
    currentPage: 1,
    perPageOptions: [10, 25, 50, 100],
    searchAmount: '',
    searchSum: '',
    searchDiscount: '',
    sortBy: 'id',
    isSortDirDesc: true,
    statusFilter: null,
    driverFilter: null,
    cashTypeFilter: null,
    dateFilterFrom: null,
    dateFilterTo: null,
    fuelFilter: null,
    issuerFilter: null,
    isExecutedFilter: null,

    driversGroupFilter: null,

  };
  const filterOptions = reactive({...filterData});

  const {
    perPage,
    totalInvoices,
    currentPage,
    perPageOptions,
    searchAmount,
    searchSum,
    searchDiscount,
    sortBy,
    isSortDirDesc,
    statusFilter,
    driverFilter,
    cashTypeFilter,
    dateFilterFrom,
    dateFilterTo,
    fuelFilter,
    issuerFilter,
    isExecutedFilter,

    driversGroupFilter

  } = toRefs(filterOptions);

  const resetFilter = () => {
    Object.assign(filterOptions, filterData);
    refetchData();
  };

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    };
  });

  const refetchData = () => {
    refInvoiceListTable.value.refresh();
  };

  watch([currentPage, perPage], () => {
    refetchData();
  });

  watch([
    searchAmount, 
    searchSum, 
    searchDiscount, 
    statusFilter, 
    driverFilter, 
    cashTypeFilter, 
    dateFilterFrom, 
    dateFilterTo, 
    fuelFilter, 
    issuerFilter, 
    isExecutedFilter,

    driversGroupFilter,

    ], () => {
    currentPage.value = 1;
    refetchData();
  });



  const fetchInvoices = (ctx, callback) => {
    store
      .dispatch('app-fuel/fetchInvoices', {
        filterSum: (searchSum.value * 100).toFixed(0),
        filterAmount: (searchAmount.value * 1000).toFixed(0),
        filterDiscount: (searchDiscount.value * 100).toFixed(0),
        size: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        filterProfileId: driverFilter.value,
        filterTypeAccount: cashTypeFilter.value,
        filterDateFrom: dateFilterFrom.value,
        filterDateTo: dateFilterTo.value,
        filterTypeFuel: fuelFilter.value,
        filterIssuerId: issuerFilter.value,
        filterIsExecuted: isExecutedFilter.value,

        filterGroupId: driversGroupFilter.value,
      })
      .then(response => {
        const fuels = response.data.items;
        const total = response.data.cursor.totalItems;

        if (response.data.calculation) {
          calculation.value.total = response.data.calculation.total;
          calculation.value.amount = response.data.calculation.amount;
          calculation.value.discount = response.data.calculation.discount;
        }

        callback(fuels);
        totalInvoices.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching fuels\' list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };



  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceStatusVariantAndIcon = directionId => {
    if (directionId == 2) return { variant: 'danger', icon: 'CheckCircleIcon' };
    if (directionId == 1) return { variant: 'success', icon: 'SendIcon' };
    return { variant: 'secondary', icon: 'XIcon' };
  };

  const resolveClientAvatarVariant = directionId => {
    if (directionId == 2) return 'primary';
    // if (directionId === 'Paid') return 'danger'
    if (directionId == 1) return 'secondary';
    // if (directionId === 'Draft') return 'warning'
    // if (directionId === 'Sent') return 'info'
    // if (directionId === 'Past Due') return 'success'
    return 'primary';
  };

  const resolveCashVariant = cashType => {
    if (cashType == '1') return 'warning';
    if (cashType == '2') return 'info';
    return 'primary';
  };

  const resolveCash = cashType => {
    if (cashType == '1') return 'Готівковий';
    if (cashType == '2') return 'Безготівковий';
  };

  const resolveStatusVariant = isExecuted => {
    if (isExecuted == true) return 'success';
    if (isExecuted == false) return 'primary';
    return 'primary';
  };

  const resolveStatus = isExecuted => {
    if (isExecuted == true) return 'Виконаний';
    if (isExecuted == false) return 'В процесі';
  };

  const cashTypeOptions = [
    { label: 'Готівковий', value: 1 },
    { label: 'Безготівковий', value: 2 },
  ];

  const isExecutedOptions = [
    { label: 'Виконаний', value: true },
    { label: 'В процесі', value: false },
  ];

  return {
    resetFilter,

    calculation,
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchSum,
    searchAmount,
    searchDiscount,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,
    driverFilter,
    cashTypeFilter,
    dateFilterFrom,
    dateFilterTo,
    fuelFilter,
    issuerFilter,
    isExecutedFilter,

    cashTypeOptions,
    isExecutedOptions,

    resolveInvoiceStatusVariantAndIcon,
    resolveClientAvatarVariant,

    resolveCashVariant,
    resolveCash,

    resolveStatusVariant,
    resolveStatus,

    refetchData,
    driversGroupFilter,

  };
}
