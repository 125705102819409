import ExtraService from '@/services/extra/extra.service';

export const extraStoreModule = {
  namespaced: true,

  state: {
    fuels: [],
    fuelsLoading: false,
  },

  actions: {
    async fetchFuels({ state, commit }) {
      commit('SET_FUELS_LOADING', true);

      if (state.fuels) {
        commit('SET_FUELS_LOADING', false);
      }

      try {
        const response = await ExtraService.getFuels();
        commit('SET_FUELS', response.data.items);
      } catch (error) {
        return error;
      } finally {
        commit('SET_FUELS_LOADING', false);
      }
    }
  },

  mutations: {
    SET_FUELS(state, data) {
      state.fuels = data;
    },
    SET_FUELS_LOADING(state, loading) {
      state.fuelsLoading = loading;
    },
  },

  getters: {
    GET_FUELS(state) {
      return state.fuels;
    },
    GET_FUELS_LOADING(state) {
      return state.fuelsLoading;
    },
  },
};
