import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'

export default function useGroupsList() {
    // Use toast
    const toast = useToast()

    const refUserListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        { key: 'title', label: "Група", sortable: false },
        { key: 'actions', label: "Дії", sortable: false },
    ]
    const perPage = ref(10)
    const totalGroups = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const balanceFilter = ref(null)
    const groupFilter = ref(null)
    const statusFilter = ref(null)

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalGroups.value,
        }
    })

    const refetchData = () => {
        refUserListTable.value.refresh()
    }

    watch([currentPage, perPage], () => {
        refetchData()
    })

    watch([searchQuery, balanceFilter, groupFilter, statusFilter], () => {
        currentPage.value = 1
        refetchData()
    })

    const fetchGroups = (ctx, callback) => {

        store
            .dispatch('app-group/fetchGroups', {
                queryName: searchQuery.value,
                size: perPage.value,
                page: currentPage.value,
            })
            .then(response => {

                const groups = response.data.items;
                const total = response.data.cursor.totalItems;

                callback(groups)
                totalGroups.value = total
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching users list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveUserBalanceVariant = balance => {
        if (balance == '1') return 'warning'
        if (balance == '2') return 'info'
        return 'primary'
    }

    const resolveUserBalance = balance => {
        if (balance == '1') return 'Баланс'
        if (balance == '2') return 'Ліміт'
    }

    const resolveUserStatus = status => {
        if (status === 'WAITING_FOR_USER_REGISTRATION') return 'Очікується реєстрація користувача'
        if (status === 'ACCOUNT_PROCESSING') return 'Обробка'
        if (status === 'WAITING_DRIVER_PIN_CONFIRMATION') return 'Очікується підтвердження PIN-коду'
        if (status === 'CONFIRMED') return 'Підтверджено'
        if (status === 'PENDING_DATEX_CREATION') return 'Очікується створення DATEX'
        if (status === 'PREPARED') return 'Готовий'
        if (status === 'ACTIVE') return 'Активний'
        if (status === 'BLOCKED') return 'Заблокований'
        return 'secondary'
    }

    const resolveUserStatusVariant = status => {
        if (status === 'WAITING_FOR_USER_REGISTRATION') return 'lighten-2'
        if (status === 'ACCOUNT_PROCESSING') return 'lighten-2'
        if (status === 'WAITING_DRIVER_PIN_CONFIRMATION') return 'secondary'
        if (status === 'CONFIRMED') return 'primary'
        if (status === 'PENDING_DATEX_CREATION') return 'lighten-2'
        if (status === 'PREPARED') return 'info'
        if (status === 'ACTIVE') return 'success'
        if (status === 'BLOCKED') return 'danger'
        return 'secondary'
    }

    return {
        fetchGroups,
        tableColumns,
        perPage,
        currentPage,
        totalGroups,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,

        resolveUserBalanceVariant,
        resolveUserBalance,
        resolveUserStatusVariant,
        refetchData,
        resolveUserStatus,

        // Extra Filters
        balanceFilter,
        groupFilter,
        statusFilter,
    }
}