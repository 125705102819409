import axiosIns from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('/business/transactions/fuels', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    fetchInvoice() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('/business/transactions/fuels')
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
  },
};
