import store from "@/store";
import {onUnmounted} from "@vue/composition-api";

export function useStoreModule(USER_APP_STORE_MODULE_NAME, module) {
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, module)

    // UnRegister on leave
    onUnmounted(() => {
        // TODO fix unregister module is removed tho its still used
        // if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
}