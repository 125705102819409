<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <app-collapse>
      <app-collapse-item title="Пошукові фільтри">
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Search -->
            <b-col cols="12" md="10">
              <div class="fuel-filters d-flex align-items-center justify-content-start">
                <div>
                  <label>Водій: </label>
                  <v-select
                    v-model="driverFilter"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="driversOptions"
                    :reduce="val => val.value"
                    class="fuel-filter-select"
                    placeholder="Виберіть водія"
                  >
                    <template #selected-option="{ label }">
                      <span class="text-truncate overflow-hidden">
                        {{ label }}
                      </span>
                    </template>
                  </v-select>
                </div>
                <div>
                  <label class="ml-1">Тип рахунку: </label>
                  <v-select
                      v-model="cashTypeFilter"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="cashTypeOptions"
                      :reduce="val => val.value"
                      class="fuel-filter-select ml-1"
                      placeholder="Виберіть тип рахунку"
                  >
                    <template #selected-option="{ label }">
                      <span class="text-truncate overflow-hidden">
                        {{ label }}
                      </span>
                    </template>
                  </v-select>
                </div>
                <div>
                  <label class="ml-1">Дата з: </label>
                  <b-form-group class="fuel-filter-select mb-0 ml-1">
                    <flat-pickr
                      v-model="dateFilterFrom"
                      class="form-control"
                      placeholder="Дата"
                    />
                  </b-form-group>
                </div>
                <div>
                  <label class="ml-1">Дата по: </label>
                  <b-form-group class="fuel-filter-select mb-0 ml-1">
                    <flat-pickr
                        v-model="dateFilterTo"
                        class="form-control"
                        placeholder="Дата"
                    />
                  </b-form-group>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="fuel-filters-row">
            <b-col>
              <div class="fuel-filters d-flex align-items-center justify-content-start">
                <div>
                  <label>Тип пального: </label>
                  <v-select
                      v-model="fuelFilter"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="fuelOptions"
                      :reduce="val => val.value"
                      class="fuel-filter-select"
                      placeholder="Виберіть тип пального"
                  >
                    <template #selected-option="{ label }">
                        <span class="text-truncate overflow-hidden">
                          {{ label }}
                        </span>
                    </template>
                  </v-select>
                </div>
                <div>
                  <label class="ml-1">АЗС: </label>
                  <v-select
                      v-model="issuerFilter"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="issuerOptions"
                      :reduce="val => val.value"
                      class="fuel-filter-select ml-1"
                      placeholder="Виберіть АЗС"
                  >
                    <template #selected-option="{ label }">
                        <span class="text-truncate overflow-hidden">
                          {{ label }}
                        </span>
                    </template>
                  </v-select>
                </div>
                <div>
                  <label class="ml-1">Статус транзакції: </label>
                  <v-select
                      v-model="isExecutedFilter"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="isExecutedOptions"
                      :reduce="val => val.value"
                      class="fuel-filter-select ml-1"
                      placeholder="Виберіть статус"
                  >
                    <template #selected-option="{ label }">
                        <span class="text-truncate overflow-hidden">
                          {{ label }}
                        </span>
                    </template>
                  </v-select>
                </div>


                <div>
                  <label class="ml-1">Група водіїв: </label>
                  <v-select
                      v-model="driversGroupFilter"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="driverGroupsOptions"
                      :reduce="val => val.value"
                      class="fuel-filter-select ml-1"
                      placeholder="Виберіть групу"
                  >
                    <template #selected-option="{ label }">
                        <span class="text-truncate overflow-hidden">
                          {{ label }}
                        </span>
                    </template>
                  </v-select>
                </div>


              </div>
            </b-col>
          </b-row>
          <b-row class="fuel-filters-row">
            <b-col>
              <div class="fuel-filters d-flex align-items-center justify-content-start">
                <div>
                  <label>Знижка: </label>
                  <b-form-input
                      v-model.number="searchDiscount"
                      type="number"
                      debounce="500"
                      class="fuel-filter-select w-25"
                      placeholder="Пошук по знижках..."
                  />
                </div>
                <div>
                  <label>Літри: </label>
                  <b-form-input
                      v-model.number="searchAmount"
                      type="number"
                      debounce="500"
                      class="fuel-filter-select w-25"
                      placeholder="Пошук по літрах..."
                  />
                </div>
                <div>
                  <label>Сума: </label>
                  <b-form-input
                      v-model.number="searchSum"
                      debounce="500"
                      class="fuel-filter-select w-25"
                      placeholder="Пошук по сумі..."
                  />
                </div>
              </div>
            </b-col>
          </b-row>
          <b-col
              cols="12"
              md="4"
              class="fuel-filter-button mb-md-0 my-2 d-flex align-items-end"
          >
            <b-button
                @click.prevent="resetFilter"
                variant="primary"
            >
              Скинути фільтр
            </b-button>
          </b-col>
          <hr/>
          <b-row class="mt-1" v-if="calculation.total">
            <b-col>
              <b-row class="justify-content-md-center font-weight-bold mb-1">Сума за період:</b-row>
              <b-row class="justify-content-md-center">
                <b-badge
                    pill
                    :variant="`light-warning`"
                    class="text-capitalize"
                >
                  {{ (calculation.total / 100).toFixed(2) }} грн.
                </b-badge>
              </b-row>
            </b-col>
            <b-col>
              <b-row class="justify-content-md-center font-weight-bold mb-1">Літри:</b-row>
              <b-row class="justify-content-md-center">
                <b-badge
                    pill
                    :variant="`light-warning`"
                    class="text-capitalize"
                >
                  {{ (calculation.amount / 1000).toFixed(2) }} л.
                </b-badge>
              </b-row>
            </b-col>
            <b-col>
              <b-row class="justify-content-md-center font-weight-bold mb-1">Знижка:</b-row>
              <b-row class="justify-content-md-center">
                <b-badge
                  pill
                  :variant="`light-warning`"
                  class="text-capitalize"
              >
                {{ (calculation.discount / 100).toFixed(2) }} грн.
              </b-badge></b-row>
            </b-col>
          </b-row>
        </div>
      </app-collapse-item>
    </app-collapse>

    <b-table
      ref="refInvoiceListTable"
      :items="fetchInvoices"
      v-bind="getTableAttributes"
      :fields="filteredColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Відповідних записів не знайдено"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <!-- Column: Actions -->
      <template #cell(actions1)="data">

        <div class="text-nowrap">
          <feather-icon
              :id="`fuel-row-${data.item.number}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mx-1"
              @click="showModal(data.item)"
          />
          <b-tooltip
              :target="`fuel-row-${data.item.number}-preview-icon`"
          />

          <!-- Dropdown -->
          <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="showModal(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Переглянути</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

      <!-- Column: Id -->
      <template #cell(id)="data">
        <b-link
          class="font-weight-bold"
          @click="showModal(data.item)"
        >
          {{ data.item.number }}
        </b-link>
      </template>

      <!-- Column: Driver -->
      <template #cell(driver)="data">
        <b-media 
          vertical-align="center" class="align-items-center" 
        >
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.user.driverFullName)"
              :variant="`light-${resolveClientAvatarVariant(data.item.directionId)}`"
            />
          </template>
          <span class="font-weight-bold d-block">
            {{ data.item.user.driverFullName ? data.item.user.driverFullName : '-' }}
          </span>
        </b-media>
      </template>

      <!-- Column: Card Number -->
      <template #cell(serialVisible)="data">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.account.datex.serialId }}
          </span>
      </template>

      <!-- Column: Account type -->
      <template #cell(typeAccount)="data">
        <b-badge
            pill
            :variant="`light-${resolveCashVariant(data.item.account.typeAccount)}`"
            class="text-capitalize"
            v-if="data.item.account.typeAccount"
        >
          {{ resolveCash(data.item.account.typeAccount) }}
        </b-badge>
      </template>

      <!-- Column: Driver group -->
      <template #cell(driverGroup)="data">
        <span
            class="text-capitalize"
            v-if="data.item.user.group"
        >
          {{ data.item.user.group.title }}
        </span>
      </template>

      <!-- Column: Client -->
      <template #cell(issuerName)="data">
        <b-media vertical-align="center" class="align-items-center">
          <template #aside>
            <b-avatar
                :id="`fuel-row-${data.item.number}`"
                size="32"
                :src="data.item.issuer.imageId ? `https://app.monobrand.club/images/company/${data.item.issuer.imageId}/logo.png` : null"
                :text="avatarText(data.item.issuer.title)"
                :variant="`light-${resolveClientAvatarVariant(data.item.directionId)}`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.issuer.title ? data.item.issuer.title : '-' }}
          </span>
          <b-tooltip
              :target="`fuel-row-${data.item.number}`"
              placement="top"
          >
            <p class="mb-0">
              {{ data.item.issuer.title }}
            </p>
            <p class="mb-0">
              {{ data.item.issuer.fuelStation.accountName }}
            </p>
            <p class="mb-0">
              {{ data.item.issuer.fuelStation.tradePointName }}
            </p>
            <p class="mb-0">
              {{ data.item.issuer.fuelStation.tradePointAddress }}
            </p>
          </b-tooltip>
        </b-media>
      </template>

      <!-- Column: Fuel type -->
      <template #cell(fuelType)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ getFuelTitle([data.item.purchase.fuelAccount.internalValue]) }}
        </span>
      </template>

      <!-- Column: Purchase Amount -->
      <template #cell(amount)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ (data.item.purchase.amount / 1000).toFixed(2) }} л.
        </span>
      </template>

      <!-- Column: Purchase total price -->
      <template #cell(sum)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ (data.item.purchase.total / 100).toFixed(2) }} грн.
        </span>
      </template>

      <!-- Column: Purchase discount -->
      <template #cell(discount)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ (data.item.purchase.discount / 100).toFixed(2) }} грн.
        </span>
      </template>

      <!-- Column: Purchase status -->
      <template #head(fuelStatus)>
        <feather-icon
            icon="TrendingUpIcon"
            class="mx-auto"
        />
      </template>

      <!-- Column: Executed status -->
      <template #cell(isExecuted)="data">
        <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.isExecuted)}`"
            class="text-capitalize"
        >
          {{ resolveStatus(data.item.isExecuted) }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions2)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`fuel-row-${data.item.number}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="showModal(data.item)"
          />
          <b-tooltip
            :target="`fuel-row-${data.item.number}-preview-icon`"
          />

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="showModal(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Переглянути</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row class="d-flex align-items-center justify-content-start">
        <label class="ml-1">Записи</label>
        <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50"
        />
        <b-col
          cols="12"
          sm="auto"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Показано {{ dataMeta.from }} до {{ dataMeta.to }} з {{ dataMeta.of }} записів</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="1"
          class="d-flex align-items-center justify-content-center justify-content-sm-end ml-auto"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

    <b-modal
      v-if="modalShow"
      v-model="modalShow"
      :title="'Транзакція #'+modalItem.number"
      ok-title="Закрити"
      ok-only
    >

      <h4 class="mb-2">Транзакція #{{ modalItem.number }}</h4>
      <div class="invoice-date-wrapper">
        <p class="invoice-date-title">
          Дата операції:
        </p>
        <p class="invoice-date">
          {{ modalItem.executedAt }}
        </p>
      </div>
      <div class="invoice-date-wrapper">
        <p class="invoice-date-title">
          Тип пального:
        </p>
        <p class="invoice-date">
          {{ modalItem.purchase.fuelAccount.externalTitle }}
        </p>
      </div>
      <div class="invoice-date-wrapper">
        <p class="invoice-date-title">
          Водій:
        </p>
        <p class="invoice-date">
          {{ modalItem.user.driverFullName }}
        </p>
      </div>
      <div class="invoice-date-wrapper">
        <p class="invoice-date-title">
          Номер карти:
        </p>
        <p class="invoice-date">
          {{ modalItem.account.datex.serialId }}
        </p>
      </div>
      <div class="invoice-date-wrapper mb-2">
        <p class="invoice-date-title">
          Тип рахунку:
        </p>
        <p class="invoice-date" v-if="modalItem.account.typeAccount">
          {{ resolveCash(modalItem.account.typeAccount) }}
        </p>
      </div>

      <h5 class="mb-1">ОПИС</h5>
      <p class="card-text mb-25">
        <b-avatar
            :id="`fuel-row-${modalItem.number}`"
            size="32"
            :src="modalItem.issuer.imageId ? `https://app.monobrand.club/images/company/${modalItem.issuer.imageId}/logo.png` : null"
            :text="avatarText(modalItem.issuer.title)"
            :variant="`light-${resolveClientAvatarVariant(modalItem.directionId)}`"
        />
        {{ modalItem.issuer.title }}
      </p>
      <p class="card-text mb-25">
        {{ modalItem.accountName }}
      </p>
      <p class="card-text mb-25">
        {{ modalItem.issuer.fuelStation.tradePointAddress }}
      </p>
      <p class="card-text mb-3">
        {{ modalItem.issuer.fuelStation.tradePointName }}
      </p>

      <h5 class="mb-1">ПІДСУМОК</h5>
      <div class="invoice-date-wrapper">
        <p class="invoice-date-title">
          Кількість:
        </p>
        <p class="invoice-date">
          {{ (modalItem.purchase.amount / 1000).toFixed(2) }} л
        </p>
      </div>
      <div class="invoice-date-wrapper">
        <p class="invoice-date-title">
          Ціна:
        </p>
        <p class="invoice-date">
          {{ (modalItem.purchase.price / 100).toFixed(2) }} грн
        </p>
      </div>
      <div class="invoice-date-wrapper">
        <p class="invoice-date-title">
          Знижка:
        </p>
        <p class="invoice-date">
          {{ (modalItem.purchase.discount / 100).toFixed(2) }} грн.
        </p>
      </div>
      <div class="invoice-date-wrapper">
        <p class="invoice-date-title">
          Всього:
        </p>
        <p class="invoice-date">
          {{ (modalItem.purchase.total / 100).toFixed(2) }} грн
        </p>
      </div>

    </b-modal>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormGroup,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BModal,
} from 'bootstrap-vue';
import {avatarText} from '@core/utils/filter';
import vSelect from 'vue-select';
import {onUnmounted} from '@vue/composition-api';
import store from '@/store';
import useInvoicesList from './useFuelList';
import flatPickr from 'vue-flatpickr-component';

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';

import fuelStoreModule from '../fuelStoreModule';
import axiosIns from '@axios';
import {useFuels} from '@/composition/extra/useFuels';

import useGroupsList from '../../group/group-list/useGroupsList';


export default {
  components: {
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BModal,
    flatPickr,
    vSelect,

    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      issuerOptions: [],
      driversOptions: [],
      driverGroupsOptions: [],
      fuelOptions: [],
      modalShow: false,
      modalItem: [],
      screenWidth: window.innerWidth,
    };
  },
  methods: {
    getDrivers: async function () {
      let driversOptions = [];

      const response = await axiosIns
        .get('/business/drivers')
        .then(response => response.data.items)
        .catch(() => false);


      response.forEach(driver => {
        driversOptions.push({
          label: driver.fullName,
          value: driver.profileId,
        });
      });

      this.driversOptions = driversOptions;
    },
    getDriverGroups: async function () {
      let driverGroupsOptions = [];

      const response = await axiosIns
        .get('/business/driver-groups')
        .then(response => response.data.items)
        .catch(() => false);

      response.forEach(driverGroup => {
        driverGroupsOptions.push({
          label: driverGroup.title,
          value: driverGroup.id,
        });
      });

      this.driverGroupsOptions = driverGroupsOptions;
    },
    getFuels: async function () {
      let fuelOptions = [];

      const response = await axiosIns
        .get('/common/extra/fuels/internal')
        .then(response => response.data.items)
        .catch(() => false);

      response.forEach(fuel => {
        fuelOptions.push({
          label: this.getFuelTitle(fuel.title),
          value: fuel.value,
        });
      });

      this.fuelOptions = fuelOptions;
    },
    getIssuers: async function () {
      let issuerOptions = [];

      const response = await axiosIns
        .get('/common/extra/issuers')
        .then(response => response.data.items)
        .catch(() => false);

      response.forEach(issuer => {
        issuerOptions.push({
          label: issuer.title,
          value: issuer.id,
        });
      });

      this.issuerOptions = issuerOptions;
    },
    showModal(item) {
      this.modalItem = item;
      this.modalShow = !this.modalShow;
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
  },
  computed: {
    isSmallScreen() {
      return this.screenWidth < 768;
    },
    getTableAttributes() {
      return this.isSmallScreen ? { stacked: true } : { responsive: true };
    },
    filteredColumns() {
      if (this.isSmallScreen) {
        return this.tableColumns.filter(column => column.mobile);
      } else {
        return this.tableColumns;
      }
    },
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.getDrivers();
    this.getFuels();
    this.getIssuers();
    this.getDriverGroups();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  setup() {

    const INVOICE_APP_STORE_MODULE_NAME = 'app-fuel';

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, fuelStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      'Виконаний',
      'В процесі',
    ];

    const {getFuelTitle} = useFuels();

    const {
      resetFilter,

      calculation,
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      // searchQuery,
      searchSum,
      searchAmount,
      searchDiscount,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      driverFilter,
      cashTypeFilter,
      dateFilterFrom,
      dateFilterTo,
      fuelFilter,
      issuerFilter,
      isExecutedFilter,

      cashTypeOptions,
      isExecutedOptions,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,

      resolveCashVariant,
      resolveCash,

      resolveStatusVariant,
      resolveStatus,

      driversGroupFilter,

    } = useInvoicesList();

    const { fetchGroups } = useGroupsList();

    return {
      resetFilter,

      calculation,
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      // searchQuery,
      searchSum,
      searchAmount,
      searchDiscount,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      driverFilter,
      cashTypeFilter,
      dateFilterFrom,
      dateFilterTo,
      fuelFilter,
      issuerFilter,
      isExecutedFilter,

      refetchData,

      statusOptions,
      cashTypeOptions,
      isExecutedOptions,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,

      resolveCashVariant,
      resolveCash,

      resolveStatusVariant,
      resolveStatus,
      getFuelTitle,

      fetchGroups,
      driversGroupFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.feather-eye {
  cursor: pointer;
}

.invoice-date-wrapper {
  display: flex;
  align-items: center;
}

.invoice-date-title {
  font-weight: 600;
  margin-right: 10px;
}

.fuel-filters {
  margin-bottom: 1rem;
}

.fuel-filter-select {
  min-width: 220px;

  .fuel-filter-row {
    margin-top: 1rem;
  }

  .fuel-form-input {
    max-width: auto;
  }

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.calculation {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .fuel-filters {
    flex-direction: column;
    align-items: flex-start !important;
    margin-bottom: 0;

    label {
      margin-left: 0 !important;
    }
  }
  .fuel-filter-select {
    margin-left: 0 !important;
    margin-bottom: 0.3rem !important;
  }
  .fuel-filter-row {
    margin-top: 0 !important;
  }
  .fuel-filter-button {
    margin-top: 1rem !important;
    padding: 0;
  }
  .custom-media-cell {
    display: flex !important;
    align-items: center !important;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
