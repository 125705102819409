import axiosIns from "@/libs/axios";

class ExtraService {

    fetchingIssuers = false;
    fetchingDiscountPolicyTypes = false
    issuers() {

        if(this.fetchingIssuers) {
            return this.fetchingIssuers;
        }

        let promise = axiosIns.get('/common/extra/issuers');

        this.fetchingIssuers = promise;

        return promise;
    };

    getFuels = () => axiosIns.get('/common/extra/fuels');

    discountPolicyTypes() {
        if(this.fetchingDiscountPolicyTypes) {
            return this.fetchingDiscountPolicyTypes;
        }

        let promise = axiosIns.get('/common/extra/discount-policy-types');

        this.fetchingDiscountPolicyTypes = promise;

        return promise;
    }
}

export default new ExtraService();